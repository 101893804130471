import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import ModalLarge from "../components/Layout/modal-large"
import ModalSmall from "../components/Layout/modal-small"

class IndexPage extends Component {
  state = {
    smallScreen: false,
    modal: { name: "" },
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }

  openModal(e) {
    this.setState({ modal: e })
    document.getElementById("modal").style.display = "block"
  }

  closeModal() {
    document.getElementById("modal").style.display = "none"
  }

  render() {
    let indexData = this.props.data.allIndexJson.nodes[0]
    let recentWork = this.props.data.allWorkJson.nodes[0].work_items.slice(0, 3)
    let homeItems = []
    let i = 0
    recentWork.forEach(item => {
      homeItems.push(
        <div>
          <div
            key={i}
            onClick={this.openModal.bind(this, item)}
            className={
              this.state.smallScreen ? "grid-item-small" : "home-grid-item"
            }
            style={{
              backgroundImage: `url(${item.image})`,
              backgroundSize: "100%",
            }}
          ></div>
          <h5 className="project-overlay">Stack used: {item.stack}</h5>
        </div>
      )
      i++
    })
    return (
      <Layout page={"home"}>
        <h1 className="title">{indexData.title}</h1>
        <div className="home-main">
          <div className="text">{indexData.text}</div>
          <div className="divider"></div>
          <h2 className="subtitle">{indexData.subtitle}</h2>
          <div
            className={
              this.state.smallScreen
                ? "grid-container-small"
                : "home-grid-container"
            }
          >
            {homeItems}
          </div>
        </div>
        <div id="modal" className="modal" onClick={this.closeModal}>
          {this.state.smallScreen ? (
            <ModalSmall modal={this.state.modal} />
          ) : (
            <ModalLarge modal={this.state.modal} />
          )}
        </div>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query {
    allIndexJson {
      nodes {
        title
        description
        text
        subtitle
      }
    }
    allWorkJson {
      nodes {
        work_items {
          name
          stack
          description
          completed
          image
          deployed
          github
        }
      }
    }
  }
`
